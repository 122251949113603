<template>
	<div id="sales-performance">
		<div class="sales-performance-container">
			<div v-if="!serverComplete" id="performance-loading">
				<span class="loading-icon"></span>
				<span class="loading-message">{{ busyText }}</span>
			</div>
			<h2>Unified Sales Report</h2>
			<div v-if="unifiedSalesReportList.length > 0">
				<FilterDateTime :dateDefaults="dateDefaults" :showExportButton="showExportButton" />
				<div>
					<UnifiedSalesReportTable
						class="performance-list"
						:selectedCasino="selectedCasino"
						:unifiedSalesReportList="unifiedSalesReportList"
						:totals="totals"
						:inGameCurrencyTool="inGameCurrencyTool"
						:systemCurrencyTool="systemCurrencyTool"
						:systemSettings="systemSettings"
					/>
				</div>
			</div>
			<h2 v-else>Unified Sales Report not available.</h2>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount, toHandlerKey } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";
import xlsxSalesStyles from "@/dependencies/xlsxSalesStyles";
import UnifiedSalesReportTable from "@/components/UnifiedSalesReportTable";
import FilterDateTime from "@/components/FilterDateTime";

export default {
	name: "UnifiedSalesReport",
	components: {
		UnifiedSalesReportTable,
		FilterDateTime,
	},
	props: {
		reporterState: Object,
		casinoList: Array,
		isMobile: Boolean,
		// casinoListForReports: Array,
		serverVersion: Object,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		systemSettings: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			selectedCasino: {},
			selectedCasinoId: this.reporterState.casinoId || this.selectedCasino.id,
			unifiedSalesReportList: [],
			serverComplete: true,
			startDate: this.getLastArchiveWeekRange(true).start,
			endDate: this.getLastArchiveWeekRange(true).end,
			casinoListForReports: [],
			dateDefaults: {},
			excelStart: 0,
			excelEnd: 0,
			totals: {
				cashInTotal: 0,
				promoInTotal: 0,
				cashOutTotal: 0,
				promoOutTotal: 0,
				mruInTotal: 0,
				mruOutTotal: 0,
				sumTotalHolds: 0,
				totalHold: {
					value: 0,
					sign: true,
				},
			},
			spacer: this.systemCurrencyTool.currencyInfo.spaceBetweenSymbol ? " " : "",
			doesSymbolGoInFront: this.systemCurrencyTool.currencyInfo.doesSymbolGoInFront,
			systemSymbol: this.systemCurrencyTool.currencyInfo.symbol,
			fileNameDateRange: `${this.startDate}_to_${this.endDate}`,
			dateFmt: "[$-409]m/d/yy\ h:mm\ AM/PM;@",
			currFmt: `"$"#,##0.00_);[Red]\("$"#,##0.00\)`,
			systemMinorWholeOrFull: this.systemCurrencyTool.displayType.minorWholeOrFull,
			systemFull: this.systemCurrencyTool.displayType.full,
			filters: null,
			showExportButton: true,
		};
	},
	watch: {
		selectedCasinoId() {
			if (this.selectedCasinoId) {
				// this.setSelectedCasino();
				this.getUnifiedSalesReport();
			}
		},
	},
	created() {
		this.listCasinosForThisReporter();
		this.setSelectedCasino();
		this.getUnifiedSalesReport();
		this.setExcellCurrencyFormat();
		this.dateDefaults = {
			startDate: this.startDate,
			endDate: this.endDate,
		};
		this.eventBus.on("applyFilters", (payload) => {
			this.applyFilters(payload);
		});
		this.eventBus.on("exportExcel", () => {
			this.exportExcel();
		});
		onBeforeUnmount(() => {
			this.eventBus.off("applyFilters");
			this.eventBus.off("exportExcel");
		});
	},
	methods: {
		setExcellCurrencyFormat() {
			let zeros = [];
			for (let index = 0; index < this.systemCurrencyTool.currencyInfo.minorDigits; index++) {
				zeros.push(0);
			}
			let decimalPlaces = this.systemCurrencyTool.currencyInfo.minorDigits > 0 ? `.${zeros.join("")}` : "";
			this.currFmt = `"${this.systemSymbol}"#,##0${decimalPlaces}_);[Red]\("${this.systemSymbol}"#,##0${decimalPlaces}\)`;
		},
		listCasinosForThisReporter() {
			if (!this.reporterState?.isSysadmin) {
				let permissionsList = this.reporterState?.userPermissions?.sitePermissions;
				for (const key in permissionsList) {
					if (permissionsList[key].includes("Reporter")) {
						let casino = this.casinoList.filter((casino) => casino.id == key)[0];
						this.casinoListForReports.push(casino);
					}
				}
			} else {
				this.casinoListForReports = this.casinoList;
			}
		},
		setSelectedCasino() {
			this.selectedCasino = this.casinoListForReports.filter((casino) => casino.id == this.selectedCasinoId)[0];
		},
		applyFilters(filters) {
			this.filters = filters;
			this.fileNameDateRange = `${this.filters.startDate}_to_${filters.endDate}`;
			this.excelStart = this.isoDateToExcelDate(this.filters.startDate,false, true);
			this.excelEnd = this.isoDateToExcelDate(this.filters.endDate,false, true);
			this.startDate = this.addISOStringOffset(this.filters.startDate);
			this.endDate = this.addISOStringOffset(this.filters.endDate);

			let filterList = [];
			for (const filter in this.filters) {
				filterList.push(this.filters[filter]);
			}
			let nullOrEmpty = (item) => item === null || item.length <= 0;
			let anyNullOrEmpty = filterList.some(nullOrEmpty);

			if (anyNullOrEmpty) {
				this.status.message = "Start Date and End Date filters are required.";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			if (this.selectedCasinoId) this.getUnifiedSalesReport();
		},
		async getUnifiedSalesReport() {
			this.busyText = `Loading Unified Sales Report for ${this.selectedCasino.name}`;
			this.serverComplete = false;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.reporterState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL("/api/v1/report/sales", this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;

			if (this.startDate) params.set("startDate", this.startDate);
			if (this.endDate) params.set("endDate", this.endDate);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {

				console.log("this is the request", request);
				let response = await fetch(request);
				console.log("this is the response", response);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.status = fetchStatus;
					this.eventBus.emit("updateStatus", this.status);
					this.serverComplete = true;
					if (fetchStatus.code === 409) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				console.log("this is the dataJson", dataJson);

				this.unifiedSalesReportList = dataJson;

				this.setSalesReportTotals();

				console.log("this is the unified sales report list", this.unifiedSalesReportList);

				this.serverComplete = true;
				this.busyText = "";
			} catch (e) {
				this.serverComplete = true;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}
		},
		calculateTotalHolds(item) {

			let cashIns = item.cashierCashInValueCOC + 	item.webCashInValueCOC +  item.mruCashInValueCOC;
			let cashOuts= item.cashierCashOutValueCOC + item.webCashOutValueCOC + item.mruCashOutValueCOC;
			let holdTotal = cashIns - cashOuts;
			// let hold = {
			// 	value:
			// 		item.cashierCashInValueCOC +
			// 		item.webCashInValueCOC +
			// 		item.webCashInValueCOC -
			// 		(item.cashierCashOutValueCOC + item.mruCashInValueCOC),
			// 	sign: true, // true is positive number,
			// };
			let hold = {
				value: holdTotal,
				sign: true,
			};
			hold.sign = Math.sign(hold.value) !== -1; // check if negative number

			return hold;
		},
		resetAllTotals() {
			this.totals = {
				cashInTotal: 0,
				promoInTotal: 0,
				cashOutTotal: 0,
				promoOutTotal: 0,
				mruInTotal: 0,
				mruOutTotal: 0,
				sumTotalHolds: 0,
				webInTotal: 0,
				webOutTotal: 0,
				totalHold: {
					value: 0,
					sign: true,
				},
			};
		},
		setSalesReportTotals() {
			this.resetAllTotals();
			if (this.unifiedSalesReportList.length > 0) {
				this.unifiedSalesReportList.forEach((item) => {
					this.totals.cashInTotal += item.cashierCashInValueCOC;
					this.totals.cashOutTotal += item.cashierCashOutValueCOC;
					this.totals.mruInTotal += item.mruCashInValueCOC;
					this.totals.mruOutTotal += item.mruCashOutValueCOC;
					this.totals.webInTotal += item.webCashInValueCOC;
					this.totals.webOutTotal += item.webCashOutValueCOC;
					item.totalHold = this.calculateTotalHolds(item);
					this.totals.sumTotalHolds += item.totalHold.value;
				});
			}
		},
		async exportExcel() {
			if (this.unifiedSalesReportList.length === 0) {
				this.status.message = "Nothing to export";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			const workbook = new this.ExcelJS.Workbook();
			workbook.creator = "Reporter App";
			workbook.lastModifiedBy = "Reporter App";
			workbook.created = new Date();
			workbook.modified = new Date();
			this.fileNameDateRange = `${this.startDate}_to_${this.endDate}`;
			if (!this.filters) {
				let filters = {
					startDate: this.startDate ? this.startDate : null,
					endDate: this.endDate ? this.endDate : null,
				};
				this.filters = filters;
				this.eventBus.emit("applyFilters", this.filters);
			}
			let contextRows = [["Unified Sales Report"], [this.serverVersion.serverName], ["From:", this.excelStart], ["To:", this.excelEnd]];
			
			//The superHeaders are the main headers that cover the top for the different sections like MRU,Web and Cashier
			let superHeader = [];
			if(this.systemSettings.businessCountry != 'MX'){
				superHeader = ["Casino", "", "Cashier", "", "MRU", "", "Web","", "Total"];
			}else{
				superHeader = ["Casino", "", "Cashier", "", "Web","", "Total"];
			}
			

			// header row
			const worksheet = workbook.addWorksheet("Unified Sales Report", {
				// freeze header rows (the + 1 is for row getting added at next insertRows)
				views: [{ state: "frozen", xSplit: 2, ySplit: contextRows.length + 2 }],
			});

			//This changes the row after the superheader
			if(this.systemSettings.businessCountry != 'MX'){
				worksheet.columns = [
					{ header: "Id", key: "casinoId", width: 7 },
					{ header: "Name", key: "casinoName", width: 22 },
					{ header: "Cash-In", key: "cashierCashInValueCOC", width: 20 },
					{ header: "Cash-Out", key: "cashierCashOutValueCOC", width: 20 },
					{ header: "Cash-In", key: "mruCashInValueCOC", width: 20 },
					{ header: "Cash-Out", key: "mruCashOutValueCOC", width: 21 },
					{ header: "Cash-In", key: "webCashInValueCOC", width: 20 },
					{ header: "Cash-Out", key: "webCashOutValueCOC", width: 20 },
					{ header: "Hold", key: "totalHold", width: 20 },
				];
			}
			else{
				worksheet.columns = [
					{ header: "Id", key: "casinoId", width: 7 },
					{ header: "Name", key: "casinoName", width: 22 },
					{ header: "Cash-In", key: "cashierCashInValueCOC", width: 20 },
					{ header: "Cash-Out", key: "cashierCashOutValueCOC", width: 20 },
					{ header: "Cash-In", key: "webCashInValueCOC", width: 20 },
					{ header: "Cash-Out", key: "webCashOutValueCOC", width: 20 },
					{ header: "Hold", key: "totalHold", width: 20 },
				];
			}

			

			worksheet.insertRows(1, contextRows);
			worksheet.mergeCells("A1:B1");
			worksheet.mergeCells("A2:B2");

			//This sets the format for the cell or column like dateFmt sets the cell for date format
			worksheet.getCell("B3").numFmt = this.dateFmt;
			worksheet.getCell("B4").numFmt = this.dateFmt;

			worksheet.getColumn("cashierCashInValueCOC").numFmt = this.currFmt;
			worksheet.getColumn("cashierCashOutValueCOC").numFmt = this.currFmt;

			if(this.systemSettings.businessCountry != 'MX'){
				worksheet.getColumn("mruCashInValueCOC").numFmt = this.currFmt;
				worksheet.getColumn("mruCashOutValueCOC").numFmt = this.currFmt;
			}
			
			worksheet.getColumn("webCashInValueCOC").numFmt = this.currFmt;
			worksheet.getColumn("webCashOutValueCOC").numFmt = this.currFmt;
			worksheet.getColumn("totalHold").numFmt = this.currFmt;

			worksheet.eachRow(function (row, rowNumber) {
				row.font = xlsxSalesStyles.defaultStyles.font;
				row.alignment = { horizontal: "left" };
			});

			// set row offset before adding, merging, and styling superHeader row
			let rowOffset = worksheet.rowCount;
			worksheet.insertRow(rowOffset, superHeader);
			this.setStylesForCells(worksheet, xlsxSalesStyles.headerStyles, rowOffset);
			worksheet.getCell(`A${rowOffset}`).fill = xlsxSalesStyles.casinoCell;
			worksheet.getCell(`C${rowOffset}`).fill = xlsxSalesStyles.cashierCell;
			worksheet.getCell(`E${rowOffset}`).fill = xlsxSalesStyles.mruCell;
			worksheet.getCell(`G${rowOffset}`).fill = xlsxSalesStyles.webcell;

			if(this.systemSettings.businessCountry != 'MX') {
				worksheet.getCell(`I${rowOffset}`).fill = xlsxSalesStyles.totalsCell;
			}
			
			// This allows for the rows for the superheader to take up as many rows as the range covers
			//i.e the line below the first superheader will cover A and B columns
			worksheet.mergeCells(`A${rowOffset}:B${rowOffset}`);
			worksheet.mergeCells(`C${rowOffset}:D${rowOffset}`);
			worksheet.mergeCells(`E${rowOffset}:F${rowOffset}`);

			if(this.systemSettings.businessCountry != 'MX'){
				worksheet.mergeCells(`G${rowOffset}:H${rowOffset}`);
			}
			

			// set row offset before adding styling to header
			rowOffset = worksheet.rowCount;
			let headerOffset = rowOffset + 1;
			this.setStylesForCells(worksheet, xlsxSalesStyles.headerStyles, rowOffset);

			this.unifiedSalesReportList.forEach((item, index) => {
				// need unique copy of sales report to keep from mutating the prop that's passed to child component
				let data = Object.assign({}, item);

				data.totalHold = data.totalHold.value;

				worksheet.addRow(data);

				rowOffset = worksheet.rowCount;

				this.setStylesForCells(worksheet, xlsxSalesStyles.basicStyles, rowOffset);
				worksheet.getCell(`B${rowOffset}`).alignment = xlsxSalesStyles.left;

				// Fill every even numbered row after header row.
				// Rows are not zero indexed so we actually are selecting for odd since we want the even rows AFTER header
				if (index % 2 !== 0) this.setSingleStyleForCells(worksheet, "fill", xlsxSalesStyles.oddFill, rowOffset);
			});

			// set row offset before inserting Totals row
			rowOffset = worksheet.rowCount + 1;
			worksheet.insertRow(rowOffset, ["Total"]);
			worksheet.mergeCells(`A${rowOffset}:B${rowOffset}`);
			worksheet.getCell(`A${rowOffset}`).alignment = xlsxSalesStyles.center;
			worksheet.getCell(`C${rowOffset}`).value = { formula: `SUM(C${headerOffset}:C${rowOffset - 1})` };
			worksheet.getCell(`D${rowOffset}`).value = { formula: `SUM(D${headerOffset}:D${rowOffset - 1})` };
			worksheet.getCell(`E${rowOffset}`).value = { formula: `SUM(E${headerOffset}:E${rowOffset - 1})` };
			worksheet.getCell(`F${rowOffset}`).value = { formula: `SUM(F${headerOffset}:F${rowOffset - 1})` };
			worksheet.getCell(`G${rowOffset}`).value = { formula: `SUM(G${headerOffset}:G${rowOffset - 1})` };
			
			if(this.systemSettings.businessCountry !='MX'){
				worksheet.getCell(`H${rowOffset}`).value = { formula: `SUM(H${headerOffset}:H${rowOffset - 1})` };
				worksheet.getCell(`I${rowOffset}`).value = { formula: `SUM(I${headerOffset}:I${rowOffset - 1})` };
			}
			
			this.setStylesForCells(worksheet, xlsxSalesStyles.totalStyles, rowOffset);

			workbook.xlsx
				.writeBuffer()
				.then((data) => {
					const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
					saveAs(blob, `Unified_Sales_Report_${this.fileNameDateRange}.xlsx`);
				})
				.catch((e) => {
					console.error(e);
					this.status.message = `Error Saving xlsx file: ${e}`;
					this.status.ok = false;
					this.eventBus.emit("updateStatus", this.status);
				});
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#performance-loading {
	display: grid;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 30px;
	background-color: rgb(0 0 0 / 70%);
	z-index: 9999;
	transition: background-color 0.3s ease-in-out;
	text-align: center;
	align-content: center;
}

#filter-container {
	display: flex;
	justify-content: center;
}

.user-message {
	margin-top: 15px;
}

.casino-balances {
	width: 100%;
	text-align: left;
	border-collapse: collapse;
}

.casino-balances {
	margin-bottom: 30px;
}

.funds-locks {
	font-weight: bold;
	color: #8f0000;
}

.casino-balances th,
.casino-balances td {
	border: 1px #000 solid;
	padding: 5px 10px;
}

.casino-balances tr:nth-child(2n) {
	background-color: #bfbfbf;
}
</style>
